import React from "react";
import styled from "@emotion/styled";
import logo from "../images/pancaIcon2.jpg";
import { Container } from "./container";
import { theme } from "./theme";
import { Link } from "gatsby";

const StyledHeader = styled.div`
	background-color: ${theme.primaryColor};
	margin-bottom: 20px;
`;

const StyledHeading = styled.h1`
	font-family: ${theme.titleFontFamily};
	line-height: 1.3;
`;

const StyledLogoLink = styled(Link)`
	text-decoration: none;
	outline: none;
`;

const StyledLogo = styled.img`
	height: 150px;
	display: block;
	margin: 0;
	margin-right: 20px;
`;

const StyledContainer = styled(Container)`
	display: flex;
	align-items: center;
	padding-bottom: 0;

	@media ${theme.mediaMobile} {
		flex-direction: column;
		align-items: flex-start;
	}
`;

function PageHeader() {
	return (
		<StyledHeader>
			<StyledContainer>
				<StyledLogoLink to="/">
					<StyledLogo src={logo} alt="Panca" />
				</StyledLogoLink>
				<StyledHeading>Dein Garshana Handschuh</StyledHeading>
			</StyledContainer>
		</StyledHeader>
	);
}

export { PageHeader };
