import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { PageHeader } from "../components/PageHeader";
import { Container } from "../components/container";
import styled from "@emotion/styled";

const StyledHeading2 = styled.h2``;

const KontraindikatorenPage = () => (
	<Layout>
		<SEO />
		<PageHeader />
		<Container>
			<StyledHeading2>Kontraindikatoren</StyledHeading2>

			<ul>
				<li>offenen Wunden</li>
				<li>Infekten</li>
				<li>Schwangerschaft</li>
				<li>Menstruation</li>
				<li>entzündlichen Prozessen</li>
				<li>akutem Fieber</li>
				<li>hohem Blutdruck</li>
				<li>Herzkrankheiten</li>
				<li>Adipositas</li>
			</ul>
			<p>...dann spreche Dich mit Deiner Ayurveda Fachperson ab.</p>
		</Container>
	</Layout>
);

export default KontraindikatorenPage;
